import React from "react";

const ImageTextBlockText = (props) => {
  const type = props.type === 'square'? 'image-text-block__image-block--square' :' image-text-block__image-block--circle';
  return (
    <div className={"image-text-block__item image-text-block__image-block image-text-block__image-block--has-text "+ type}>
      <div dangerouslySetInnerHTML={{__html: props.textContent}}/>
    </div>
  )
}

export default ImageTextBlockText
