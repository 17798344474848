import {graphql, useStaticQuery} from 'gatsby';

const ImageBlockQuery = () => {
  return useStaticQuery(graphql`  query MyQuery
    {
      allHomeTextImageBlockJson {
        nodes {
          id
          sectionTitle
          sectionContent
          textContent
          shape
          textPosition
          hasCta
          cta {
            buttonText
            url
          }
        }
      }
      allWhyBenefitsJson {
        nodes {
          id
          image
          sectionContent
          sectionTitle
          textPosition
          cta {
            url
            buttonText
          } 
          imageSize
        }
      }
     allWhyTextImageBlockJson {
       nodes {
         id
         textPosition
         sectionTitle
         sectionContent
         shape
       }
     }
    }
  `);

};

export default ImageBlockQuery;

