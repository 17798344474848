import React from "react";

const ImageTextBlockImage = (props) => {
  const imageUrl = props.textImage;
  const imageSize = props.imageSize === 'small' ? 'img-fluid' : '';
  function getShape(shape='') {
    switch (shape) {
      case 'square':
        return 'image-block__shape  image-block__shape--square';
      case 'circle':
        return 'image-block__shape image-block__shape--circle';
      default:
        return '';
    }
  }
  return (
    <div className={"image-text-block__item image-text-block__image-block " + getShape(props.type)}>
      <div className="image-block__image-wrapper ">
        <img className={`image-block__image ` + imageSize} src={imageUrl} alt={props.imgAlt}/>
      </div>
    </div>
  )
}
export default ImageTextBlockImage

