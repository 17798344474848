import React from "react"

import Layout from "../components/layout"

import Hero from "../components/hero"
import ImageCta from "../components/image-cta"
import Resource from "../components/resource"
import CardColumn from "../components/card-column"
import SEO from "../components/seo"
import ImageTextBlock from "../components/image-text-block";
import AboutCta from "../components/about-cta";

//hooks
import useHero from '../hooks/use-hero';
import ImageBlockQuery from "../hooks/use-imagetextblock"
import useCardColumn from '../hooks/use-cardcolumn';
import useResourcesFilters from '../hooks/use-resources-filters';
import useResources from '../hooks/use-resources';
import useImageCta from "../hooks/use-imagecta";


const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Home" description="Achieving a harmonious balance of intentionally connecting and disconnecting helps us build a more sustainable relationship with technology that empowers"/>
      <Hero data={useHero()}></Hero>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {
              ImageBlockQuery().allHomeTextImageBlockJson.nodes.map((items, index) => (
                <ImageTextBlock key={items['id']}
                  textBlockPosition={items['textPosition']}
                  sectionClassName="home-page-block"
                  sectionTitle={items['sectionTitle']}
                  type="text"
                  textContent={items['textContent']}
                  shape={items['shape']}
                  hasCta={items['hasCta']}
                  ctaOptions={items['cta']}
                >
                  {items['sectionContent']}
                </ImageTextBlock>
              ))
            }


          </div>
        </div>
      </div>
      <ImageCta data={useImageCta()}></ImageCta>
      <CardColumn data={useCardColumn()}></CardColumn>
      <Resource
        filters={useResourcesFilters()}
        data={useResources()}
        page={'home'}
        count={10}
      >
      </Resource>
      <AboutCta hasImage={true}/>
    </Layout>
  )
}

export default IndexPage
