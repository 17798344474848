import React from "react";
import ImageTextBlockImage from "./image-text-block--image";
import ImageTextBlockText from "./image-text-block--text";
import {Link} from "gatsby"


const ImageTextBlock = (props) => {
  const sectionClassName = props.sectionClassName;
  const blockPosition = props.textBlockPosition === 'right' ? 'image-text-block--text-right' : '';
  const hasCta = props.hasCta === 'yes';
  const type = props.type === 'text' ? 'text' : 'image';
  return (
    <div className={sectionClassName + " image-text-block " + blockPosition + " image-text-block--has-" + type}>
      <div className="image-text-block__item image-text-block__text-block">
        <div className="image-text-block__text-container">
          <div className="image-text-block__text-title">
            <div dangerouslySetInnerHTML={{__html: props.sectionTitle}}/>
          </div>
          <div className="image-text-block__text-description" dangerouslySetInnerHTML={{__html: props.children}}/>
          {hasCta && <Link className="button button--green-ghost image-text-block__button d-none d-lg-inline-block" to={props.ctaOptions.url}>{props.ctaOptions.buttonText}</Link>}
        </div>
      </div>
      {type === 'text' && <ImageTextBlockText textContent={props.textContent} type={props.shape}/>}
      {type === 'image' && <ImageTextBlockImage imageSize={props.imageSize} textImage={props.imageUrl} type={props.shape} imgAlt={props.sectionTitle}/>}
      {hasCta && <Link className="button button--green-ghost image-text-block__button d-lg-none" to={props.ctaOptions.url}>{props.ctaOptions.buttonText}</Link>}

    </div>
  )
}

export default ImageTextBlock
