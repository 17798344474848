import { useStaticQuery, graphql } from 'gatsby';

const useHero = () => {
  const data = useStaticQuery(graphql`
    {
      allHeroHomeJson {
        nodes {
          title
          desktopImage
          mobileImage
          primaryButton {
            text
            link
          }
          secondaryButton {
            text
            link
          }
        }
      }
    }
  `);

  return data.allHeroHomeJson.nodes[0];
};

export default useHero;
