import { useStaticQuery, graphql } from 'gatsby';

const useCardColumn = () => {
  const data = useStaticQuery(graphql`
    {
      allCardColumnJson {
        nodes {
          title
          sectionLabel
          tile {
            image
            headline
            url
            buttonText
          }
        }
      }
    }
  `);

  return data.allCardColumnJson.nodes[0];
};

export default useCardColumn;
