import React from "react"
import {Link} from "gatsby"

const SimpleTile = (props) => {

  const block = 'simple-tile'

  return (
    <div className="col-md-6 col-lg-3">
      <Link to={props.url + '#' + props.headline.replace(/\s+/g, '-').toLowerCase()} aria-label={props.buttonText} className={block}>
        <div className={`${block}__image-wrapper`}>
          <img src={props.image} className={`${block}__image`} alt=""/>
        </div>
        <div className={`${block}__headline small-title`}>{props.headline}</div>
        <div className={`${block}__buttonText button button--green-simple`}>{props.buttonText}</div>
      </Link>
    </div>
  )
}

export default SimpleTile
