import React from "react"
import SimpleTile from "../components/simple-tile"

import SectionImage from '../images/tips-hero.svg';

const CardColumn = (props) => {

  const data = props.data;
  const block = 'card-column'

  return (
  <div className={block}>
    <div className="container">
      <div className="row align-items-stretch">
        <div className="col-12">
          <div className={`${block}__label section-label`}>{ data.sectionLabel }</div>
          <div className={`${block}__title section-title-lg`}><div dangerouslySetInnerHTML={{__html: data.title}}/></div>
          <div className={`${block}__image-wrap d-none d-sm-block`}>
            <img src={ SectionImage } alt={`BalanceTech Tips Graphic`} />
          </div>
        </div>
      </div>
    </div>
    <div className={`${block}__image-wrap--mobile d-sm-none`}>
      <img src={ SectionImage } alt={`BalanceTech Tips Graphic`} />
    </div>
    <div className={`${block}__wrap`}>
      <div className="container">
        <div className="row align-items-stretch">
          <div className="col-12">
            <div className={`${block}__tiles`}>
              <div className="row">
                {
                  data.tile.map((items, index) => (

                    <SimpleTile
                      headline={items['headline']}
                      image={items['image']}
                      url={items['url']}
                      buttonText={items['buttonText']}
                      key={index}
                    >
                    </SimpleTile>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CardColumn
