import React from "react"
import DisconnectTypeForm from "./typeFromPoup";

const ImageCta = (props) => {

  const data = props.data;

  return (
  <div className="image-cta">
    <div className="container">
      <div className="image-cta__wrapper">
        <div className="image-cta__image-wrapper"></div>
        <div className="image-cta__body">
          <h2 className="image-cta__title">{ data.title }</h2>
          <div className="image-cta__description">
            { data.description }
          </div>
          { data.button.link ?
            <div className="image-cta__cta">
              <DisconnectTypeForm formURL={ data.button.link } className="typeform-share button button--yellow  image-cta__button quiz-btn-secondary" buttonText={ data.button.text }/>
            </div>
            : ""
          }
        </div>
      </div>
    </div>
  </div>
  )
}

export default ImageCta
