import React from 'react';
import {ReactTypeformEmbed} from 'react-typeform-embed'

class DisconnectTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.openForm = this.openForm.bind(this);
  }

  openForm() {
    this.typeformEmbed.typeform.open();
  }

  render() {
    const data = this.props;
    return (
      <>
        <button className={data.className} onClick={this.openForm} style={{cursor: 'pointer'}} aria-label={data.buttonText}>
          {data.buttonText}
        </button>
        <ReactTypeformEmbed
          popup={true}
          autoOpen={false}
          url={data.formURL}
          hideHeaders
          hideFooter
          autoClose={90000000000000000000}
          ref={tf => {
            this.typeformEmbed = tf;
          }}
        />

      </>
    );
  }
}

export default DisconnectTypeForm;
