import React from "react"
import { Link } from "gatsby"
import Lottie from 'lottie-react-web'
import animation from '../animations/home-hero.json';


import DisconnectTypeForm from './typeFromPoup'
const Hero = (props) => {

  const data = props.data;

   return (
  <div className="hero">
    <div className="container">
      <h1 className="hero__title page-title"dangerouslySetInnerHTML={{__html: data.title}}></h1>
      <div className="hero__button-wrapper">
        <DisconnectTypeForm formURL={ data.primaryButton.link } className={`typeform-share button button--green hero__button quiz-btn-primary`} buttonText={ data.primaryButton.text }/>
        <Link to={ data.secondaryButton.link } aria-label={ data.secondaryButton.text } className="button button--green-ghost hero__button">{ data.secondaryButton.text }</Link>
      </div>
      <div className="hero__image-container">
        <div className="hero__image-wrapper">
        { animation ?
            <Lottie
              options={{
                animationData: animation
              }}
            />
          : ""
          }
        </div>
      </div>
    </div>
  </div>
  )
}

export default Hero
