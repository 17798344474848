import { useStaticQuery, graphql } from 'gatsby';

const useImageCta = () => {
  const data = useStaticQuery(graphql`
    {
      allImageCtaJson {
        nodes {
          title
          description
          button {
            text
            link
          }
        }
      }
    }
  `);

  return data.allImageCtaJson.nodes[0];
};

export default useImageCta;
